import React from "react";
import { CheckIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline'
import Layout from "../../components/Layout"
import ReactTooltip from "react-tooltip";

//eslint-disable-next-line no-extend-native
String.prototype.format = String.prototype.f = function() {
  var s = this,
      i = arguments.length;

  while (i--) {
      s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), arguments[i]);
  }
  return s;
};

const FREE_LIMIT = 100;

const features = {
  TRACKING: 'Tracking confirmations',
  STATUS_BAR: 'Subscription status bar',
  SNIPER_LINKS: 'Sniper links',
  HOSTED_CONF_PAGES: 'Hosted confirmation pages',
  BRANDING_ON: 'Subscribe Sense branding',
  REMINDERS: 'Email reminders',
  // TEAM_SIZE_1: 'One team member',
  // Maybe add referring domains limitations eventually?

  // Pro Plan
  EMBED_CONF_WIDGETS: 'Hosted confirmation page or embeddable widgets for self-hosted pages',
  // TEAM_SIZE_3: 'Up to 3 team members',
  // LIST_SIZE_3: 'Up to 3 lists',

  // Premium Plan
  BRANDING_OFF: 'Subscribe Sense branding removed',
  // UNLIMITED_TEAM_SIZE: 'Unlimited team members',
  // UNLIMITED_LISTS: 'Unlimited number of lists',
  PRORITY_SUPPORT: 'Priority support',
  CONF_LIMIT: 'Up to {0} subscriber confirmations per month',
  CONF_LIMIT_FREE: `Up to ${FREE_LIMIT} subscriber confirmations per month`,
  // eslint-disable-next-line no-template-curly-in-string
  OVERAGE: 'Additional subscriber confirmations billed at ${0} each',
}


const helpText = {
  [features.TRACKING]: "Track all the people who try to sign up for your list, regardless of whether they confirm.",
  [features.STATUS_BAR]: "Let leads know what email address they signed up with, if the confirmation email to them bounced, or if their confirmation was successfully received.",
  [features.SNIPER_LINKS]: "Include a link that opens the lead's webmail client in a new tab and pulls up your confirmation email (even if it's in their spam folder for many providers).",
  [features.EMBED_CONF_WIDGETS]: `
    The status bar and sniper link widgets can either exist on a page hosted by Subscribe Sense that you redirect your leads
    to after they submit their email address, or you can embed them using Javascript on your own self-hosted confirmation page.
  `,
  [features.HOSTED_CONF_PAGES]: "You must use a confirmation page hosted by Subscribe Sense that contain the status bar and sniper link widgets.",
  [features.BRANDING_ON]: "A Subscribe Sense logo will appear either on the hosted confirmation page or in the Status Bar widget.",
  [features.REMINDERS]: "Optionally send out reminder emails 24-48 hours after a lead signs up if they don't confirm.",
  [features.BRANDING_OFF]: "No Subscribe Sense branding on confirmation pages or in embedded Status Bar widgets.",
  [features.PRORITY_SUPPORT]: "One-on-one support for implementing Subscribe Sense into your lead collection, and priority support for any ongoing needs.",
  [features.CONF_LIMIT]: "Only subscribers who confirm after visiting a confirmation page hosted by Subscribe Sense or with embedded Subscribe Sense widgets count towards this limit.",
  [features.CONF_LIMIT_FREE]: `
    Only subscribers who confirm after visiting your Subscribe Sense confirmation page count towards this limit.
    Subsequent leads can still subscribe but will not see any Subscribe Sense features.
  `,
  [features.OVERAGE]: "After the limit, additional confirmations are charged individually. Limits reset monthly at the beginning of the billing period."
}

const featureId = {
  [features.TRACKING]: 'tracking',
  [features.STATUS_BAR]: 'status-bar',
  [features.SNIPER_LINKS]: 'sniper-links',
  [features.HOSTED_CONF_PAGES]: 'hosted-conf',
  [features.BRANDING_ON]: 'branding',
  [features.REMINDERS]: 'remidners',
  // TEAM_SIZE_1: 'One team member',
  // Maybe add referring domains limitations eventually?

  // Pro Plan
  [features.EMBED_CONF_WIDGETS]: 'embedded-conf',
  // TEAM_SIZE_3: 'Up to 3 team members',
  // LIST_SIZE_3: 'Up to 3 lists',

  // Premium Plan
  [features.BRANDING_OFF]: 'branding-off',
  // UNLIMITED_TEAM_SIZE: 'Unlimited team members',
  // UNLIMITED_LISTS: 'Unlimited number of lists',
  [features.PRORITY_SUPPORT]: 'priority-support',
  [features.CONF_LIMIT]: 'conf-limit',
  [features.CONF_LIMIT_FREE]: 'conf-limit-free',
  [features.OVERAGE]: 'overage',
}

const default_cta = "Try free for 30 days"
const pricing = {
  tiers: [
    {
      stripe_id: process.env.GATSBY_SS_STRIPE_PROD_FREE,
      title: 'Free Plan',
      price: 0,
      description: 'Anyone can start reducing friction for their leads.',
      max_confs: FREE_LIMIT,
      features: [
          features.TRACKING,
          features.STATUS_BAR,
          features.SNIPER_LINKS,
          features.REMINDERS,
          features.HOSTED_CONF_PAGES,
          features.BRANDING_ON,
          features.CONF_LIMIT_FREE,
          // features.TEAM_SIZE_1,
      ],
      cta: "Get started",
    },
    {
      stripe_id: process.env.GATSBY_SS_STRIPE_PROD_STARTER,
      title: 'Starter Plan',  
      price: 20,
      frequency: '/month',
      description: 'For lists that are starting to gain traction.',
      max_confs: 500,
      overage_rate: 0.04,
      features: [
        features.TRACKING,
        features.STATUS_BAR,
        features.SNIPER_LINKS,
        features.REMINDERS,
        features.HOSTED_CONF_PAGES,
        features.BRANDING_ON,
        features.CONF_LIMIT,
        features.OVERAGE,
        // # features.TEAM_SIZE_1,
      ],
      cta: default_cta,
      mostPopular: false,
    },
    {
      stripe_id: process.env.GATSBY_SS_STRIPE_PROD_PRO,
      title: 'Professional Plan',
      price: 30,
      frequency: '/month',
      description: 'For lists that are hitting their stride.',
      max_confs: 1000,
      overage_rate: 0.03,
      features: [
        features.TRACKING,
        features.STATUS_BAR,
        features.SNIPER_LINKS,
        features.REMINDERS,
        features.EMBED_CONF_WIDGETS,
        features.BRANDING_ON,
        features.CONF_LIMIT,
        features.OVERAGE,
        // features.TEAM_SIZE_3,
      ],
      cta: default_cta,
      mostPopular: true,
    },
    {
      stripe_id: process.env.GATSBY_SS_STRIPE_PROD_PREMIUM,
      title: 'Premium Plan',
      price: 100,
      frequency: '/month',
      description: 'For lists at scale.',
      max_confs: 5000,
      overage_rate: 0.02,
      features: [
        features.TRACKING,
        features.STATUS_BAR,
        features.SNIPER_LINKS,
        features.REMINDERS,
        features.EMBED_CONF_WIDGETS,
        features.BRANDING_OFF,
        features.CONF_LIMIT,
        features.OVERAGE,
        // features.UNLIMITED_TEAM_SIZE,
        features.PRORITY_SUPPORT
      ],
      cta: 'Get Started',
      mostPopular: false,
    },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Pricing = () => (
  <Layout pageTitle="Pricing | Subscribe Sense" pageDescription="Subscribe Sense pricing - plans for any size list.">
    <div className="mx-auto max-w-7xl bg-white py-24 px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:leading-none lg:text-6xl">
        Plans to grow your list at any stage.
      </h2>
      <p className="mt-6 max-w-2xl text-xl text-gray-500">
        Choose an affordable plan that's packed with the best features for growing your audience, creating customer loyalty, and driving sales.
      </p>

      {/* Tiers */}
      <div className="mt-24 space-y-12 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0 lg:-mx-12">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative flex flex-col rounded-2xl border border-gray-200 bg-white px-8 lg:px-6 py-8 shadow-sm"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">{tier.title}</h3>
              {tier.mostPopular ? (
                <p className="absolute top-0 -translate-y-1/2 transform rounded-full bg-indigo-500 py-1.5 px-4 text-sm font-semibold text-white">
                  Most popular
                </p>
              ) : null}
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-5xl font-bold tracking-tight">${tier.price}</span>
                <span className="ml-1 text-xl font-semibold">{tier.frequency}</span>
              </p>
              <p className="mt-6 text-gray-500">{tier.description}</p>

              {/* Feature list */}
              <ul className="mt-6 space-y-6">
                {tier.features.map((feature) => (
                  <li key={feature} className="flex mx-0">
                    <CheckIcon className="h-6 w-6 flex-shrink-0 text-indigo-500" aria-hidden="true" />
                    <div className="ml-3 text-gray-500">
                      <span>{feature === features.CONF_LIMIT ? feature.format(tier.max_confs) : feature === features.OVERAGE ? feature.format(tier.overage_rate) : feature }</span>
                      {!!helpText[feature] &&
                      <span className="inline whitespace-nowrap">&nbsp;<QuestionMarkCircleIcon className="h-4 w-4 text-blue-700 inline -mt-1" data-tip data-for={`${featureId[feature]}`}/></span>
                      }
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <a
              href={`${process.env.GATSBY_SS_APP_DOMAIN}/accounts/signup/?next=/subscriptions/purchase/${tier.stripe_id}/`}
              className={classNames(
                tier.mostPopular
                  ? 'bg-indigo-500 text-white hover:bg-indigo-600'
                  : 'bg-indigo-50 text-indigo-700 hover:bg-indigo-100',
                'mt-8 block w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'
              )}
            >
              {tier.cta}
            </a>
          </div>
        ))}
      </div>
      {Object.keys(helpText).map((key) => (
        <ReactTooltip
          id={`${featureId[key]}`} type="info" effect="solid" className="xs:max-w-xs sm:max-w-sm md:max-w-md max-w-lg"
          overridePosition={({ left, top }, _e, _t, node) => {
            return {
              top,
              left: typeof node === 'string' ? left : Math.max(left, 0),
            };
          }}
        >
          <div className="text-lg">
            {helpText[key]}
          </div>
        </ReactTooltip>
      ))}
    </div>
  </Layout>
);

export default Pricing;